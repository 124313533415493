<template>
  <div>
    <EditDialog
      width="80%"
      :isReturn="true"
      v-if="isExpenditure"
      @closeFn="$emit('closeExpenditure')"
      :isShow.sync="isExpenditure"
    >
      <div slot="title" class="title">经费预支详情</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-form ref="form" :model="form">
          <el-descriptions style="width: 100%" direction="vertical" :column="7" border>
            <el-descriptions-item label="单据编号" prop="expenseNumber" ref="expenseNumber">
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ form.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="申请人">
              <el-form-item prop="createName" label-width="0px">
                {{ form.createName }}
              </el-form-item></el-descriptions-item
            >
            <el-descriptions-item label="部门"
              ><el-form-item prop="deptId" label-width="0px">
                {{ form.deptName }}
              </el-form-item></el-descriptions-item
            >
            <el-descriptions-item label="申请日期">
              <el-form-item prop="taskDate" label-width="0px">
                {{ form.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 总额度（元） </template>
              <el-form-item prop="sumQuota" label-width="0px">
                {{ form.sumQuota | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <div>审批中金额（元）</div>
                <!-- <div style="color: red">包含本次单据金额</div> -->
              </template>
              <el-form-item prop="sumUnfinishedFlowCost" label-width="0px">
                {{ form.sumUnfinishedFlowCost | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 未冲销金额合计（元） </template>
              <el-form-item prop="sumNotCounteractCost" label-width="0px">
                {{ form.sumNotCounteractCost | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 是否超额 </template>
              <el-form-item prop="" label-width="0px">{{ excess }}</el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 超出额度（元） </template>
              <el-form-item prop="" label-width="0px">
                {{ beyondMoney | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>
                费用所属单位
              </template>
              <el-form-item prop="companyType" label-width="0px">
                {{ form.companyType | dict(companyTypeList) }}
              </el-form-item></el-descriptions-item
            >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>
                本次申请金额（元）
              </template>
              <el-form-item prop="cost" label-width="0px">
                {{ form.cost | applyAmount }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" :span="2" style="width: 100%">
              <el-form-item label-width="0px" prop="" style="width: 100%">
                {{ amount | numberParseChina }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="预支冲销/归还日期">
              <template slot="label">
                <span style="color: red">*</span>
                预支冲销/归还日期
              </template>
              <el-form-item prop="predictCounteractDate" label-width="0px">
                {{ form.predictCounteractDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <template v-if="form.status == 100">
              <el-descriptions-item label="发放日期">
                <el-form-item prop="payedDate" label-width="0px">
                  {{ form.payedDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="截止归还日期">
                <el-form-item prop="counteractDeadline" label-width="0px">
                  {{ form.counteractDeadline | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="实际归还时间">
                <el-form-item prop="actualCounteractDate" label-width="0px">
                  {{ form.actualCounteractDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="发放备注" :span="4">
                <el-form-item prop="remark" label-width="0px">
                  {{ form.remark }}
                </el-form-item>
              </el-descriptions-item>
            </template>
            <el-descriptions-item label="项目名称" :span="7">
              <template slot="label">
                <span style="color: red">*</span>
                项目名称
              </template>
              <el-form-item prop="projectContent" label-width="0px">
                {{ form.projectContent }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item label="用途说明" :span="7">
              <template slot="label">
                <span style="color: red">*</span>
                用途说明
              </template>
              <el-form-item prop="descriptionAbout" label-width="0px">
                {{ form.descriptionAbout }}
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <template
          v-if="form.counteractExpenseListResp && form.counteractExpenseListResp.length > 0"
        >
          <el-table
            :data="form.counteractExpenseListResp"
            border
            id="table"
            style="margin-top: 10px"
            :row-style="{ height: '80px' }"
          >
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

            <el-table-column
              prop="expenseNumber"
              align="center"
              label="单据编号"
              min-width="160"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              prop="createdDate"
              align="center"
              label="填报日期"
              min-width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="expenseType" label="费用类型">
              <template slot-scope="scope">
                {{ scope.row.expenseType | dict(expenseTypeCounteract) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              align="center"
              label="冲销金额"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="scope.row.expenseType === 'RETURN_EXPENDITURE'"
                  type="primary"
                  @click="fundsDetailFn(scope.row)"
                  >详情</el-button
                >
                <!-- v-if="scope.row.expenseType != 'RETURN_EXPENDITURE'" 财务归还列表移除 -->
                <el-button size="mini" v-else type="primary" @click="costDetailFn(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 冲销金额合计，本单未冲销金额 -->
          <div style="height: 20px"></div>

          <el-descriptions title="" :column="4" border>
            <el-descriptions-item label="冲销金额合计" labelStyle="width:120px">
              {{ writOffMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:120px">
              {{ writOffMoney | numberParseChina }}
            </el-descriptions-item>
            <el-descriptions-item label="本单未冲销金额" labelStyle="width:120px">
              {{ thisWrittenOffMoney | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:120px">
              {{ thisWrittenOffMoney | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>
        </template>

        <OpinionArea
          ref="opinionArea"
          :procInstId="form.processInstId"
          :instInvolved="instInvolved"
          :title="'经费预支申请进度'"
        ></OpinionArea>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="$emit('closeExpenditure')">返回</el-button>
      </div>
    </EditDialog>

    <!-- 报销详情 -->
    <CostDetails
      v-if="form.counteractExpenseListResp && form.counteractExpenseListResp.length > 0"
      @CostDetailsFn="CostDetailsFn"
      :costDetailDialog_show.sync="costDetailDialog"
      :options="costOptions"
    ></CostDetails>
  </div>
</template>

<script>
export default {
  name: 'ExpenditureAdvanceDetails',
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    CostDetails: () => import('@/components/funds/cost-details.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      isExpenditure: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      form: {
        // 申请 表单数据
      },
      companyTypeList: [], //费用所属单位
      expenseTypeCounteract: [], //费用类型-冲销
      costOptions: {}, //报销详情
      costDetailDialog: false, //报销详情
      instInvolved: {}, //当前进度处理人数组
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.isExpenditure = newVal
      },
    },
    options: {
      // immediate: true,
      deep: true,
      handler: function (newVal) {
        if (newVal.id) {
          this.getDetails()
        }
      },
    },
  },
  created() {
    // 费用所属单位
    this.$api.dict
      .listSysDictData('COMPANY_TYPE')
      .then(res => {
        this.companyTypeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('EXPENSE_TYPE_COUNTERACT', true)
      .then(res => {
        this.expenseTypeCounteract = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  computed: {
    amount() {
      let num = 0
      if (this.form.cost) {
        num = Number(this.form.cost)
      }
      return num
    },
    // 超额
    excess() {
      const Quota = Number(this.form.sumQuota)
      const UnfinishedFlow = Number(this.form.sumUnfinishedFlowCost)
      const NotCounteract = Number(this.form.sumNotCounteractCost)
      if (UnfinishedFlow + NotCounteract > Quota) {
        return '是'
      } else {
        return '否'
      }
    },
    // 超出金额
    beyondMoney() {
      let num = 0
      const Quota = Number(this.form.sumQuota)
      const UnfinishedFlow = Number(this.form.sumUnfinishedFlowCost)
      const NotCounteract = Number(this.form.sumNotCounteractCost)
      num = UnfinishedFlow + NotCounteract - Quota
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    // 冲销金额合计
    writOffMoney() {
      let num = 0
      if (this.form.counteractExpenseListResp && this.form.counteractExpenseListResp.length > 0) {
        this.form.counteractExpenseListResp.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 本单未冲销金额
    thisWrittenOffMoney() {
      let num = 0
      num = this.form.cost - this.writOffMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
  },
  methods: {
    // 明细归还详情
    CostDetailsFn() {
      this.costOptions = {}
      this.costDetailDialog = false
    },
    // 明细归还详情
    fundsDetailFn(row) {
      this.costOptions = row.deepClone()
      this.costDetailDialog = true
    },
    // 预支经费中报销
    CostDetailsFn() {
      this.costOptions = {}
      this.costDetailDialog = false
    },
    // 预支经费中报销
    costDetailFn(row) {
      this.costOptions = row.deepClone()
      this.costDetailDialog = true
    },

    /** 加载 "经费申请" 详情数据 **/
    async getDetails() {
      this.loading = true
      this.$api.funds
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.form = res.data
            this.form.sumUnfinishedFlowCost += Number(this.form.cost)
            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.color_r {
  color: #f00;
}

.el-form-item {
  margin-bottom: 0px !important;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
